<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>

  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
      <vs-popup title="Les preuves" :active.sync="preuves" class="items-no-padding">
        <input type="file" class="hidden" ref="updateImgInput1" @change="updateCurrImg1" multiple accept="*">

        <div class="upload-img mt-5 mb-2">
          <vs-button icon-pack="feather" icon="icon-plus" @click="$refs.updateImgInput1.click()">Preuves</vs-button>
        </div>

        <vs-list>
          <vs-list-header title="Preuves"></vs-list-header>
          <vs-list-item v-for="(data, io) in dataImg1_file" :key="io + '458'" :title="'Preuves ' + io" :subtitle="data.name"></vs-list-item>
        </vs-list>

        <vs-list v-if="input.prospectProofs && input.prospectProofs.length">
          <vs-list-header title="Preuves enrégistrées"></vs-list-header>
        </vs-list>

        <p v-for="(data, io) in input.prospectProofs" :key="io + '458'" @click="ouvrirImage(data.url)" class="cursor-pointer">
          {{ 'Preuves ' + io }}
        </p>

        <vs-button class="mt-5" icon-pack="feather" icon="icon-check" @click="preuves = false">ok</vs-button>
      </vs-popup>

      <div class="mt-6 flex items-center justify-between px-6">
          <h4>{{ Object.entries(this.data).length === 0 ? "AJOUTER UN" : (detail__detail ? 'DÉTAIL DU ' : "MODIFICATION DE") }} PROSPECT</h4>
          <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>
      <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
        <div class="p-6" v-if="!detail__detail">
          <vs-button type="border" class="mt-5" icon-pack="feather" icon="icon-image" @click="preuves = true">Preuves</vs-button>
          <div v-if="is_admin">
          <p class="pt-4"> Apporteur d'affaire <b style="color: #ff6141" >*</b> </p>
          <vs-select
            v-model="input.parent"
            autocomplete
            class="mt-5 w-full"
            name="contributor"
            v-validate="'required'">
            <vs-select-item :key="item" :value="item.id" :text="item.full_name" v-for="item in contributors" />
          </vs-select>
          <span class="text-danger text-sm" v-show="errors.has('contributor')">{{ errors.first('contributor') }}</span>
          </div>
          <p class="pt-4"> Type prospect <b style="color: #ff6141" >*</b> </p>
          <v-select
            v-validate="'required'"
            name="prospectType"
            :reduce="rep => rep.key"
            class="w-full" label="name"
            v-model="input.prospectType"
            :options="prospectTypes">
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('prospectType')">{{ errors.first('prospectType') }}</span>

          <p class="pt-4"> Type carte <b style="color: #ff6141" >*</b> </p>
          <v-select
            v-validate="'required'"
            name="idType"
            :reduce="rep => rep.key"
            class="w-full" label="name"
            v-model="input.idType"
            :options="cartype">
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('idType')">{{ errors.first('idType') }}</span>

          <div v-if="input.prospectType === 'corporate'">
            <p class="pt-4"> Nom de l'entreprise <b style="color: #ff6141" >*</b> </p>
            <vs-input
              v-validate="'required'"
              name="companyName"
              v-model="input.companyName"
              class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('companyName')">{{ errors.first('companyName') }}</span>
          </div>

          <div v-if="input.prospectType === 'corporate' || input.prospectType === 'particular'">
            <p v-if="input.prospectType === 'corporate'" class="pt-4"> NIF <b style="color: #ff6141" >*</b> </p>
            <p v-if="input.prospectType === 'particular'" class="pt-4"> CNI <b style="color: #ff6141" >*</b> </p>
            <vs-input
              v-validate="'required|max:12'"
              name="cni"
              type="cni"
              v-model="input.cni"
              class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('cni')">{{ errors.first('cni') }}</span>
          </div>

          <div v-if="input.prospectType === 'particular'">
            <p class="pt-4"> Nom <b style="color: #ff6141" >*</b> </p>
            <vs-input
              v-validate="'required'"
              name="last_name"
              v-model="input.last_name"
              class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>

            <p class="pt-4"> Prénoms <b style="color: #ff6141" >*</b> </p>
            <vs-input
              v-validate="'required'"
              name="first_name"
              v-model="input.first_name"
              class="w-full" />
            <span class="text-danger text-sm" v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>

            <p class="pt-4"> Sexe <b style="color: #ff6141" >*</b> </p>
            <v-select
              v-validate="'required'"
              name="gender"
              :reduce="rep => rep.key"
              class="w-full" label="name"
              v-model="input.gender"
              :options="genders">
            </v-select>
            <span class="text-danger text-sm" v-show="errors.has('gender')">{{ errors.first('gender') }}</span>
          </div>

          <div v-if="input.prospectType === 'corporate' || input.prospectType === 'particular'">
            <p class="pt-4" v-if="input.prospectType === 'corporate'"> Date de création <b style="color: #ff6141" >*</b> </p>
            <p class="pt-4" v-if="input.prospectType === 'particular'"> Date de naissance <b style="color: #ff6141" >*</b> </p>
            <datepicker
              label-placeholder="Select Date"
              v-model="input.birthDate"
              v-validate="'required'"
              name="birthDate"
              class="w-full"
              ></datepicker>
            <span class="text-danger text-sm" v-show="errors.has('birthDate')">{{ errors.first('birthDate') }}</span>
          </div>

          <p class="pt-4"> Nom d'utilisateur <b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required'"
            name="username"
            v-model="input.username"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('username')">{{ errors.first('username') }}</span>

          <p class="pt-4"> Nombre de personne <b style="color: #ff6141" >*</b> </p>
          <vs-input-number
            class="w-full ml-0"
            v-model="input.numberOfPerson"
            v-validate="'required'"
            name="numberOfPerson"
            />
          <span class="text-danger text-sm" v-show="errors.has('numberOfPerson')">{{ errors.first('numberOfPerson') }}</span>

          <p class="pt-4"> Email <b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required|email'"
            name="email"
            type="email"
            v-model="input.email"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>

          <p class="pt-4"> Numéro de téléphone <b style="color: #ff6141" >*</b> </p>
          <vue-tel-input
            v-on:country-changed="countryChanged"
            inputOptions.placeholder="Telephone"
            :selectedCountryCode="true"
            v-model="input.phoneNumber"
            name="phoneNumber"
            data-vv-validate-on="blur"
            v-validate="'required|min:8|max:16'"
          >
          </vue-tel-input>
          <span class="text-danger text-sm" v-show="errors.has('phoneNumber')">{{ errors.first('phoneNumber') }}</span>


          <p class="pt-4"> Pays <b style="color: #ff6141" >*</b> </p>
          <vs-select v-model="input.country" autocomplete  class="mt-5 w-full" name="country" v-validate="'required'">
            <vs-select-item :key="item" :value="item.id" :text="item.name" v-for="item in countries" />
          </vs-select>
          <span class="text-danger text-sm" v-show="errors.has('country')">{{ errors.first('country') }}</span>

          <p class="pt-4"> Ville <b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="city"
            v-model="input.city"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('city')">{{ errors.first('city') }}</span>


        </div>

        <vs-list v-if="detail__detail">
          <vs-list-header icon-pack="feather" icon="icon-user-check" title="Information"></vs-list-header>

          <vs-list-item icon-pack="feather" icon="icon-check" title="Apporteur d'affaire">
            <vs-chip color="warning" class="product-order-status">{{ input.parent ? input.parent.full_name : '' }}</vs-chip>
          </vs-list-item>

          <vs-list-item icon-pack="feather" icon="icon-check" title="Pack">
            <vs-chip color="success" class="product-order-status">{{ input.pack ? input.pack.name : '' }}</vs-chip>
          </vs-list-item>

          <vs-list-item icon-pack="feather" icon="icon-check" title="Type prospect">
            <vs-chip color="primary" class="product-order-status">{{ prospectTypesObject[input.prospectType] }}</vs-chip>
          </vs-list-item>

          <vs-list-item icon-pack="feather" icon="icon-check" title="Type de carte" :subtitle="PreuveObject[input.idType]"></vs-list-item>

          <vs-list-item v-if="input.prospectType === 'corporate'" icon-pack="feather" icon="icon-check" title="Nom de l'entreprise" :subtitle="input.companyName"></vs-list-item>
          <vs-list-item icon-pack="feather" icon="icon-check" :title="input.prospectType === 'corporate' ? 'NIF' : 'CNI'" :subtitle="input.cni"></vs-list-item>

          <div v-if="input.prospectType === 'particular'">
            <vs-list-item icon-pack="feather" icon="icon-check" title="Nom" :subtitle="input.last_name"></vs-list-item>
            <vs-list-item icon-pack="feather" icon="icon-check" title="Prénoms" :subtitle="input.first_name"></vs-list-item>
            <vs-list-item icon-pack="feather" icon="icon-check" title="Sexe" :subtitle="gendersObject[input.gender]"></vs-list-item>
          </div>

          <vs-list-item icon-pack="feather" icon="icon-check" :title="input.prospectType === 'corporate' ? 'Date de création' : 'Date de naissance'" :subtitle="input.birthDate"></vs-list-item>
          <vs-list-item icon-pack="feather" icon="icon-check" title="Email" :subtitle="input.email"></vs-list-item>
          <vs-list-item icon-pack="feather" icon="icon-check" title="Nom d'utilisateur" :subtitle="input.username"></vs-list-item>
          <vs-list-item icon-pack="feather" icon="icon-check" title="Nombre de personne" :subtitle="String(input.numberOfPerson)"></vs-list-item>
          <vs-list-item icon-pack="feather" icon="icon-check" title="Ville" :subtitle="input.city"></vs-list-item>
          <vs-list-item icon-pack="feather" icon="icon-check" title="Numéro de téléphone" :subtitle="input.phoneNumber"></vs-list-item>

        </vs-list>
      </component>

      <div class="flex flex-wrap items-center p-6" slot="footer" v-if="!detail__detail">
        <vs-button class="mr-6" @click="Prospect_validate">Soumettre</vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Annuler</vs-button>
      </div>
  </vs-sidebar>
</template>

<script>
import Datepicker from 'vuejs-datepicker'

// For custom error message
import { Validator } from 'vee-validate'

const dict = {
  custom: {
    last_name: {
      required: 'Le champ nom est obligatoire'
    },
    first_name: {
      required: 'Le champ prénoms est obligatoire'
    },
    birthDate: {
      required: 'Le champ date de naissance est obligatoire'
    },
    gender: {
      required: 'Le champ sexe est obligatoire'
    },
    cni: {
      required: 'Le champ cni est obligatoire'
    },
    username: {
      required: 'Le champ nom d\'utilisateur est obligatoire'
    },
    email: {
      required: 'Le champ email est obligatoire'
    },
    contributor: {
      required: 'Le champ prospect est obligatoire'
    },

    city: {
      required: 'Le champ prospect est obligatoire'
    },
    country: {
      required: 'Le champ pays est obligatoire'
    },
    phoneNumber: {
      required: 'Le champ numéro de téléphone est obligatoire'
    },
    numberOfPerson: {
      required: 'Le champ nombre de personne est obligatoire'
    },
    idType: {
      required: 'Le champ Type de carte est obligatoire'
    }
  }
}

// register custom messages
Validator.localize('en', dict)
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

let is_admin = false
const user_role = JSON.parse(localStorage.getItem('userInfo')).role
if (user_role === 'admin') {
  is_admin = true
}
const input_tempon = {
  username: '',
  email: '',
  parent:'',
  first_name: '',
  last_name: '',
  birthDate: '',
  gender: '',
  cni: '',
  idType: '',
  // ajout
  prospectType: '',
  numberOfPerson: '',
  companyName: '',
  phoneNumber: '',
  city: '',
  defaultCountryCode: '+228',
  country: ''
}

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker
  },
  data () {
    return {
      detail__detail: true,
      preuves: false,
      dataImg1: [],
      keys: [],
      is_admin,
      dataImg1_delete: [],
      dataImg1_file: [],
      prospectTypesObject: {
        particular: 'Particulier',
        corporate: 'Entreprise'
      },
      prospectTypes: [
        {
          key: 'particular',
          name: 'Particulier'
        },
        {
          key: 'corporate',
          name: 'Entreprise'
        }
      ],
      gendersObject: {
        male: 'Masculin',
        female: 'Féminin'
      },
      PreuveObject: {
        passport: 'Passport',
        idCard: 'Carte d\'identité'
      },

      genders: [
        {
          key: 'male',
          name: 'Masculin'
        },
        {
          key: 'female',
          name: 'Féminin'
        }
      ],
      cartype: [
        {
          key: 'passport',
          name: 'Passport'
        },
        {
          key: 'idCard',
          name: 'Carte d\'identité'
        }
      ],
      contributors: [],
      countries: [],
      input: JSON.parse(JSON.stringify(input_tempon)),
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        this.input = JSON.parse(JSON.stringify(this.data))
        this.initValues()
      }
      if (this.data.detail__detail) {
        this.detail__detail = true
      } else {
        this.detail__detail = false
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    ouvrirImage (url) {
      window.open(this.$store.state.image_url + url, '_blank')
    },
    countryChanged (country) {
      const countryCode = country.dialCode
      // this.input.phoneNumber = '+'+country.dialCode
      this.$http.post('code-country/', { countryCode })
        .then((response) => {
          this.input.country = response.data[0].id
          // console.log("voir le pays",response.data[0].name)
        })
        .catch(() => {})


    },
    updateCurrImg1 (input) {
      const base_self = this
      if (input.target.files && input.target.files[0]) {
        const length = input.srcElement.files.length
        for (let iuoy = 0; iuoy < length; iuoy++) {
          const reader = new FileReader()
          reader.onload = e => {
            // unshift
            base_self.dataImg1.unshift(e.target.result)
            base_self.dataImg1_file.unshift(base_self.$refs.updateImgInput1.files[iuoy])
          }
          reader.readAsDataURL(input.target.files[iuoy])
        }
      }
    },
    initValues () {
      if (this.data.id) return
      this.input = JSON.parse(JSON.stringify(input_tempon))
    },
    Prospect_validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitData()
        }
      })
    },
    async submitData () {
      this.$vs.loading()
      const input = JSON.parse(JSON.stringify(this.input))
      input.birthDate = this.$store.state.convertDateTime(new Date(input.birthDate)).date
      const form = new FormData()
      if (input.prospectType === 'corporate') {
        this.keys = ['username', 'email', 'first_name', 'last_name', 'birthDate', 'cni', 'prospectType', 'numberOfPerson', 'companyName', 'phoneNumber', 'city', 'country', 'parent', 'defaultCountryCode', 'idType']
      } else if (input.prospectType === 'particular') {
        this.keys = ['username', 'email', 'first_name', 'last_name', 'birthDate', 'gender', 'cni', 'prospectType', 'numberOfPerson', 'companyName', 'phoneNumber', 'city', 'country', 'parent', 'defaultCountryCode', 'idType']
      }
      let length = this.keys.length
      let iuoy = 0
      for (iuoy = 0; iuoy < length; iuoy++) {
        const item = this.keys[iuoy]
        form.append(item, input[item])
      }

      length = this.dataImg1_file.length
      for (iuoy = 0; iuoy < length; iuoy++) {
        /* if (input.id) {
          form.append('pictures_add', this.dataImg1_file[iuoy], this.dataImg1_file[iuoy].name)
        } else {
          form.append('proof', this.dataImg1_file[iuoy], this.dataImg1_file[iuoy].name)
        } */
        form.append('proof', this.dataImg1_file[iuoy], this.dataImg1_file[iuoy].name)
      }

      // proof

      let url = 'prospects/'
      let methods = 'post'
      const message = {
        error: 'Votre enrégistrement a échoué.',
        success: 'Le prospect est enrégistrer avec succès.'
      }
      if (input.id) {
        url += `${input.id}/`
        methods = 'put'
        message.success = 'Le prospect est modifié avec succès.'
      }

      this.$http[methods](url, form)
        .then((response) => {
          window.getProspect.getAllProspects()
          window.getPrendTaCom.success(message.success, response)
          this.$emit('closeSidebar')
          this.initValues()
        })
        .catch((error) => {
          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]
            let libelle = ''

            if (item === 'username') {
              libelle = 'Nom d\'utilisateur'
            } else if (item === 'email') {
              libelle = 'Email'
            } else if (item === 'password') {
              libelle = 'Mot de passe'
            } else if (item === 'password2') {
              libelle = 'Confirmer le mot de passe'
            } else if (item === 'first_name') {
              libelle = 'Prénoms'
            } else if (item === 'last_name') {
              libelle = 'Nom'
            } else if (item === 'birthDate') {
              libelle = 'Date de naissance'
            } else if (item === 'gender') {
              libelle = 'Sexe'
            } else if (item === 'city') {
              libelle = 'Pays'
            } else if (item === 'idType') {
              libelle = 'Type de carte'
            }

            for (let j = 0; j < error.response.data[item].length; j++) {
              window.getPrendTaCom.error(`${libelle} :  ${error.response.data[item][j]}`)
            }
          }
          window.getPrendTaCom.error(message.error)
        })
    },
    updateCurrImg (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    getAllContries () {
      //delete this.$http.defaults.headers.common['Authorization']
      this.$http.get('countries/')
        .then((response) => {
          this.countries = response.data
        })
        .catch(() => {
        })
    }
  },
  async created () {

    this.getAllContries()

    this.$http.get('contributors/')
      .then((response) => { this.contributors = response.data })
      .catch((error) => { console.log(error) })
    /*
    this.$http.get('cities/')
      .then((response) => { this.cities = response.data })
      .catch((error) => { console.log(error) })
 */
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}
</style>
